<template>
  <Content
    :manual-liquidation="manualLiquidation.item"
    @click-button="manuallyTriggerLiquidation"
  />
</template>

<script>
import Content from './Content.vue';

import EndorsementService from '@/services/osiris/EndorsementService';
import CercEndorsementService from '@/services/osiris/Cerc/EndorsementService';

const services = {
    TAG: EndorsementService,
    CERC: CercEndorsementService,
};

export default {
    components: {
        Content,
    },

    props: {
        manualLiquidation: {
            type: Object,
            default: () => ({
                item: {},
            }),
        },

        register: {
            type: String,
            default: () => 'TAG',
        },
    },

    methods: {
        async manuallyTriggerLiquidation(value) {
            const actionIsConfirmed = await this.confirmAnAction('Deseja realmente lançar essa liquidação manual?');
            if (!actionIsConfirmed) return;
            this.isLoading(true);
            const { status } = await services[this.register].addLiquidationValue(
                this.manualLiquidation.item.id,
                value,
            );
            this.isLoading(false);

            if (status === 200) {
                this.modalSuccess('Liquidação manual lançada com sucesso');
                this.manualLiquidation.item.paid_value = value;
            } else this.modalError('Não foi possível lançar a liquidação manual');
        },
    },
};
</script>
