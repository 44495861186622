<template>
  <CreditPlatformItemProductLayout
    :merchant-selected-is-required="false"
    :optin-selected-is-required="false"
    :merchant="endorsement.merchant"
    :breadcrumb-items="[
      {
        text: 'Inicio',
        href: '/plataforma-credito',
      },
      {
        text: 'Detalhes Estabelecimento',
        href: `/plataforma-credito/estabelecimento/detalhes/${endorsement.merchant.id}`,
      },
      {
        text: 'Averbação',
      },
    ]"
  >

    <b-card>
      <validation-observer
        ref="averbationForm"
        #default="{ invalid }"
      >
        <form-averbation
          disabled-fields
          :register="endorsement.register"
          :endorsement="endorsement"
        />

        <h3> URs já cadastradas </h3>

        <contract-specifications
          :is-percentage="endorsement.division_method === 'percentage'"
          :contract-specifications="endorsement.contract_specifications"
          :total-contract-specification-pages="totalContractSpecificationPages"
          :is-edit="true"
          :hide-controls="true"
          :effect-type="endorsement.effect_type_id"
          :register="endorsement.register"
          :fields="[
            { key: 'id', label: 'id' },
            { key: 'expected_settlement_date', label: 'previsão da liquidação', sortable: true, },
            { key: 'receivable_debtor', label: 'adquirente' },
            { key: 'payment_scheme', label: 'esquema' },
            { key: 'effect_value', label: 'Valor Averbado', sortable: true, },
            { key: 'effect_priority', label: 'Posição na Fila', sortable: true, },
            { key: 'total_value', label: 'Valor total da UR', sortable: true, },
            { key: 'charge_value', label: 'Valor Alcançado', sortable: true, },
            { key: 'time', label: 'Prazo' },
            { key: 'actions', label: 'Ações' },
          ]"
          @change-page="(page) => $emit('change-page', page)"
          @import="handleImportedCsv"
        />

        <h3> Novas URs </h3>

        <contract-specifications
          :is-percentage="endorsement.division_method === 'percentage'"
          class="mb-2"
          :fields="contractSpecificationFields"
          :effect-type="endorsement.effect_type_id"
          :register="endorsement.register"
          :total-contract-specification-pages="totalContractSpecificationPages"
          :contract-specifications="newContractSpecifications"
          @import="handleImportedCsv"
        />

        <b-row
          style="gap: 12px"
          class="justify-content-center"
        >
          <b-button
            variant="primary"
            :disabled="invalid"
            @click="validateAverbation"
          >
            Salvar Averbação
          </b-button>

          <b-button
            variant="danger"
            :disabled="endorsement.status_id === 1"
            @click="cancelEndorsement"
          >
            Cancelar Averbação
          </b-button>

          <b-button
            variant="primary"
            @click="downloadEndorsement"
          >
            Baixar Averbação
          </b-button>
        </b-row>
      </validation-observer>
    </b-card>

  </CreditPlatformItemProductLayout>
</template>

<script>
import {
    BCard, BButton, BRow,
} from 'bootstrap-vue';

import {
    ValidationObserver,
} from 'vee-validate';
import { DateTime } from 'luxon';
import FormAverbation from '../../Components/Form.vue';
import ContractSpecifications from '../../Components/ContractSpecifications/ContractSpecifications.vue';

import EndorsmentDownloader from './download-endorsement';

import CreditPlatformItemProductLayout from '@/views/layouts/CreditPlatformItemProductLayout.vue';

import TableContractSpecification from '../../Components/ContractSpecifications/Table.vue';

const removeSymbolsMoney = value => (typeof value === 'string'
    ? +value.replace(/[R$ .]/g, '').replace(',', '.')
    : value);

const removeSymbolsTaxId = taxId => taxId.replace(/[^a-zA-Z0-9]/g, '');

export default {
    components: {
        BButton,
        BCard,
        BRow,
        FormAverbation,
        ContractSpecifications,
        ValidationObserver,
        CreditPlatformItemProductLayout,
    },

    props: {
        endorsement: {
            type: Object,
            required: true,
        },

        totalContractSpecificationPages: {
            type: Number,
            default: () => 0,
        },
    },

    data() {
        return {
            newContractSpecifications: [],
        };
    },

    computed: {
        contractSpecificationFields() {
            const noContainsInDivisionMethodPercentage = ['effect_value'];

            const fields = [
                { key: 'expected_settlement_date', label: 'previsão da liquidação' },
                { key: 'receivable_debtor', label: 'cnpj adquirente' },
                { key: 'payment_scheme', label: 'esquema' },
                { key: 'effect_value', label: 'Valor Efetivo' },
                { key: 'actions', label: 'Ações' },
            ];

            if (this.endorsement.division_method === 'percentage') {
                return fields.filter(item => !noContainsInDivisionMethodPercentage.find(key => key === item.key));
            }

            return fields;
        },
    },

    methods: {
        async validateAverbation() {
            const isConfirmedAction = await this.confirmAnAction('Deseja realmente editar essa averbação');
            if (!isConfirmedAction) return;

            this.$refs.averbationForm.validate().then(success => {
                if (success) { this.updateEndorsement(); }
            });
        },

        updateEndorsement() {
            const { endorsement } = this;
            const isPercentage = endorsement.division_method === 'percentage';

            const request = {
                ...endorsement,
                percentage: isPercentage ? +endorsement.percentage : undefined,
                contract_value: removeSymbolsMoney(endorsement.contract_value),
                is_cancelled: false,
                bank_account: {
                    ...endorsement.bank_account,
                    bank: endorsement.bank_account.bank.bank,
                    document_number: removeSymbolsTaxId(endorsement.bank_account.document_number),
                },

                contract_specifications: endorsement.register === 'CERC' ? this.newContractSpecifications : this.newContractSpecifications.filter(item => item.is_possible_edit).map(item => ({
                    ...item,
                    effect_value: isPercentage ? undefined : removeSymbolsMoney(item.effect_value),
                    receivable_debtor: removeSymbolsTaxId(item.receivable_debtor),
                    is_editable: undefined,
                    is_possible_edit: undefined,
                })),

            };

            this.$emit('click-update-button', request);
        },

        async cancelEndorsement() {
            const response = await this.confirmAnAction('Deseja realmente cancelar essa averbação?');

            if (!response) return;

            this.$emit('click-cancel-endorsement', {
                register: this.endorsement.register,
                id: this.endorsement.id,
            });
        },

        handleImportedCsv(event) {
            // eslint-disable-next-line no-restricted-syntax
            for (const contractSpecificationFile of event) {
                this.newContractSpecifications.push(contractSpecificationFile);
            }
        },

        async downloadEndorsement() {
            new EndorsmentDownloader({
                ...this.endorsement,
                merchant_name: this.endorsement.merchant.legal_tax_name,
                contract_specifications: this.endorsement.contract_specifications.map(item => ({
                    ...item,
                    time: DateTime.now().toFormat('yyyy-MM-dd') > item.expected_settlement_date ? 'Expirado' : 'A Expirar',
                })),
            }).generate();
        },
    },
};
</script>
