<template>
  <Page
    v-if="endorsement"
    :total-contract-specification-pages="totalContractSpecificationPages"
    :endorsement="endorsement"
    @change-page="handleChangePage"
    @click-update-button="updateEndorsement"
    @click-cancel-endorsement="cancelEndorsement"
  />
</template>

<script>
import Page from './Page.vue';

import EndorsementService from '@/services/osiris/EndorsementService';
import CercEndorsementService from '@/services/osiris/Cerc/EndorsementService';

import TagMapper from '../../mappers/TAG';
import CercMapper from '../../mappers/CERC';

const services = {
    TAG: EndorsementService,
    CERC: CercEndorsementService,
};

const mappers = {
    TAG: TagMapper,
    CERC: CercMapper,
};

export default {
    components: {
        Page,
    },

    data() {
        return {
            endorsement: null,
            totalContractSpecificationPages: 0,
            cache: new Map(),
        };
    },

    mounted() {
        this.getEndorsement();
    },

    methods: {
        async getEndorsement() {
            this.isLoading(true);
            const requests = Object.values(services).map(service => service.show(this.$route.params.id));

            try {
                const { data } = await (Promise.all(requests)).then(response => response.find(item => item.status === 200));
                const contractSpecifications = await services[data.opt_in.register].endorsementSpecification({ endorsementId: data.id, pageSize: 10 });
                const endorsement = mappers[data.opt_in.register].toView(data);
                this.totalContractSpecificationPages = contractSpecifications.data.meta.total;
                endorsement.contract_specifications = [];
                this.endorsement = endorsement;
                await this.handleChangePage(1);
            } catch (err) {
                console.log(err);
                this.$router.push('/error-404');
            }

            this.isLoading(false);
        },

        async handleChangePage(page) {
            this.isLoading(true);
            const contractSpecifications = this.cache.get(page) || await services[this.endorsement.register].endorsementSpecification({ endorsementId: this.endorsement.id, pageSize: 10, page });
            if (!this.endorsement.contract_specifications?.length) {
                this.endorsement.contract_specifications = [];
            }
            this.endorsement.contract_specifications = TagMapper.toViewEndorsements(contractSpecifications.data.data);
            this.endorsement.contract_specifications = this.endorsement.contract_specifications.sort((a, b) => a.expected_settlement_date.localeCompare(b.expected_settlement_date));
            this.isLoading(false);
        },

        async cancelEndorsement(endorsement) {
            this.isLoading(true);

            const { status } = await services[endorsement.register].delete(endorsement.id);

            const responseStatus = {
                200: 'Averbação cancelada com sucesso',
            };

            this.isLoading(false);

            if (status === 200) {
                this.modalSuccess(responseStatus[status]);
            } else if (status === 403) {
                this.modalError('Está fora do horário de funcionamento, tente mais tarde');
            } else {
                this.modalError('Erro ao editar averbação');
            }
        },

        async updateEndorsement(endorsement) {
            this.isLoading(true);

            const { status, data: dataResponse } = await services[endorsement.register].update({
                id: this.$route.params.id,
                data: mappers[endorsement.register].map(endorsement, true),
            });

            const responseStatus = {
                200: 'Averbação editada com sucesso',
                202: 'Está fora do horário de funcionamento, porém sua edição foi agendada',
            };

            this.isLoading(false);

            if (status === 200 || status === 202) {
                this.modalSuccess(responseStatus[status]);
            } else if (status === 502 && this.endorsement.register === 'CERC') {
                this.modalError(dataResponse.message);
            } else {
                this.modalError('Não foi possível editar essa averbação');
            }
        },
    },
};
</script>
