import BaseOsirisService from '../BaseOsirisService';

const BASE_ENDORSEMENT = 'cerc/endorsement';

class EndorsementService extends BaseOsirisService {
    async create(data) {
        try {
            const response = await this.api.post(BASE_ENDORSEMENT, data);
            return response;
        } catch ({ response }) {
            return response;
        }
    }

    async endorsementSpecification({
        page = 1, pageSize = 100, endorsementId,
    }) {
        try {
            const response = await this.api.get('/cerc/endorsement-specifications', {
                params: {
                    page, page_size: pageSize, endorsement_id: endorsementId,
                },
            });
            return response;
        } catch ({ response }) {
            return response;
        }
    }

    async index() {
        try {
            return await this.api.get(BASE_ENDORSEMENT);
        } catch ({ response }) {
            return response;
        }
    }

    async show(id) {
        try {
            return await this.api.get(`${BASE_ENDORSEMENT}/${id}`);
        } catch ({ response }) {
            return response;
        }
    }

    async update({ id, data }) {
        try {
            return await this.api.put(`${BASE_ENDORSEMENT}/${id}`, data);
        } catch ({ response }) {
            return response;
        }
    }

    async delete(id) {
        try {
            return await this.api.delete(`${BASE_ENDORSEMENT}/${id}`);
        } catch ({ response }) {
            return response;
        }
    }

    async addLiquidationValue(id, value) {
        try {
            return await this.api.post(`/liquidation/endorsement/effect/${id}`, {
                manual_posting: value,
            });
        } catch ({ response }) {
            return response;
        }
    }

    async getSummary() {
        try {
            return await this.api.get('/overall/endorsement/summary');
        } catch ({ response }) {
            return response;
        }
    }

    async overall({
        page,
        limit,
    }) {
        try {
            return await this.api.get('/overall/endorsement/', {
                params: {
                    page,
                    limit,
                },
            });
        } catch ({ response }) {
            return response;
        }
    }
}

export default new EndorsementService();
