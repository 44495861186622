<template>
  <b-row>
    <b-breadcrumb
      class="breadcrumb-chevron mb-0"
    >
      <b-breadcrumb-item
        v-for="item of items"
        :key="item.text"
        :class="item.href || 'breadcrumb-color'"
        @click="pushRoute(item.href)"
      >
        {{ item.text }}
      </b-breadcrumb-item>
    </b-breadcrumb>
  </b-row>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem, BRow } from 'bootstrap-vue';

export default {
    components: {
        BBreadcrumb, BRow, BBreadcrumbItem,
    },

    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        pushRoute(route) {
            this.$router.push(route);
        },
    },
};
</script>

  <style lang="scss" scoped>
    .breadcrumb-color {
      a {
        color: #5e5873 !important;
        cursor: default;
      }
    }
  </style>
