const banks = {
    'Banco A.J. RENNER - 92874270': 1,
    'Banco ABC Brasil - 28195667': 2,
    'Banco ABN AMRO - 03532415': 3,
    'Banco ALFA - 03323840': 4,
    'Banco ARBI - 54403563': 5,
    'Banco AZTECA do Brasil - 09391857': 6,
    'Banco BARCLAYS - 61146577': 7,
    'Banco BBM - 15114366': 8,
    'Banco BMFBOVESPA - 00997185': 9,
    'Banco BMG - 61186680': 10,
    'Banco BNP PARIBAS Brasil - 01522368': 11,
    'Banco BOA VISTA INTERATLANTICO - 33485541': 12,
    'Banco BONSUCESSO - 71027866': 13,
    'Banco BRACCE - 48795256': 14,
    'Banco BRADESCARD - 04184779': 15,
    'Banco BRADESCO - 60746948': 16,
    'Banco BRADESCO BBI - 60746948': 17,
    'Banco BRADESCO CARTÕES - 59438325': 18,
    'Banco BRADESCO FINANCIAMENTOS - 07207996': 19,
    'Banco BTG PACTUAL - 30306294': 20,
    'Banco CACIQUE - 33349358': 21,
    'Banco CAIXA GERAL – Brasil - 33466988': 22,
    'Banco CAPITAL - 15173776': 23,
    'Banco CARGILL - 03609817': 24,
    'Banco CEDULA - 33132044': 25,
    'Banco CETELEM - 00558456': 26,
    'Banco CIFRA - 62421979': 27,
    'Banco CITIBANK - 33479023': 28,
    'Banco CLÁSSICO - 31597552': 29,
    'Banco COOPERATIVO do Brasil – Bancoob - 02038232': 30,
    'Banco COOPERATIVO SICREDI - 01181521': 31,
    'Banco CREDIT AGRICOLE Brasil - 75647891': 32,
    'Banco CREDIT SUISSE (Brasil) - 32062580': 33,
    'Banco da AMAZONIA - 04902979': 34,
    'Banco da CHINA Brasil - 10690848': 35,
    'Banco DAYCOVAL - 62232889': 36,
    'Banco de LA NACION ARGENTINA - 33042151': 37,
    'Banco de LA PROVINCIA de BUENOS AIRES - 44189447': 38,
    'Banco de LA REPUBLICA ORIENTAL DEL URUGUAY - 51938876': 39,
    'Banco de TOKYO MITSUBISHI UFJ Brasil - 60498557': 40,
    'Banco DIBENS - 61199881': 41,
    'Banco do BRASIL - 00000000': 42,
    'Banco do ESTADO de SERGIPE - 13009717': 43,
    'Banco do ESTADO do PARA - 04913711': 44,
    'Banco do ESTADO do RIO GRANDE do SUL (BANRISUL) - 92702067': 45,
    'Banco do NORDESTE do Brasil - 07237373': 46,
    'Banco FATOR - 33644196': 47,
    'Banco FIBRA - 58616418': 48,
    'Banco FICSA - 61348538': 49,
    'Banco GERADOR - 10664513': 50,
    'Banco GUANABARA - 31880826': 51,
    'Banco INDUSTRIAL do Brasil - 31895683': 52,
    'Banco INDUSTRIAL e COMERCIAL - 07450604': 53,
    'Banco INDUSVAL - 61024352': 54,
    'Banco INTERCAP - 58497702': 55,
    'Banco INTERMEDIUM - 00416968': 56,
    'Banco INVESTCRED UNIBANCO - 61182408': 57,
    'Banco ITAU BBA - 17298092': 58,
    'Banco ITAÚ HOLDING FINANCEIRA - 60872504': 59,
    'Banco J. SAFRA - 03017677': 60,
    'Banco J.P. MORGAN - 33172537': 61,
    'Banco JOHN DEERE - 91884981': 62,
    'Banco KDB do Brasil - 07656500': 63,
    'Banco KEB do Brasil - 02318507': 64,
    'Banco LUSO BRASILEIRO - 59118133': 65,
    'Banco MÁXIMA - 33923798': 66,
    'Banco MERCANTIL do BRASIL - 17184037': 67,
    'Banco MIZUHO do Brasil - 61088183': 68,
    'Banco MODAL - 30723886': 69,
    'Banco MORGAN STANLEY DEAN WITTER - 02801938': 70,
    'Banco ORIGINAL - 92894922': 71,
    'Banco ORIGINAL do Agronegócio - 09516419': 72,
    'Banco PANAMERICANO - 59285411': 73,
    'Banco PAULISTA - 61820817': 74,
    'Banco PECUNIA - 60850229': 75,
    'Banco PETRA - 11758741': 76,
    'Banco PINE - 62144175': 77,
    'Banco POTTENCIAL - 00253448': 78,
    'Banco RABOBANK INTERNATIONAL Brasil - 01023570': 79,
    'Banco RANDON - 11476673': 80,
    'Banco RENDIMENTO - 68900810': 81,
    'Banco RIBEIRAO PRETO - 00517645': 82,
    'Banco RODOBENS - 33603457': 83,
    'Banco SAFRA - 58160789': 84,
    'Banco SANTANDER (Brasil) - 90400888': 85,
    'Banco SEMEAR - 00795423': 86,
    'Banco SOCIETE GENERALE Brasil - 61533584': 87,
    'Banco SOFISA - 60889128': 88,
    'Banco SUMITOMO MITSUI Brasileiro - 60518222': 89,
    'Banco TOPAZIO - 07679404': 90,
    'Banco TRIÂNGULO - 17351180': 91,
    'Banco VOTORANTIM - 59588111': 92,
    'Banco VR - 78626983': 93,
    'Banco WESTERN UNION do Brasil - 13720915': 94,
    'Banco WOORI BANK do Brasil - 15357060': 95,
    'BANESTES (Banco do ESTADO do ESPIRITO SANTO) - 28127603': 96,
    'BANIF – Banco INTERNACIONAL do FUNCHAL (Brasil) - 33884941': 97,
    'BANK OF AMERICA MERRILL LYNCH Banco Múltiplo - 62073200': 98,
    'BCV – Banco de Crédito e Varejo - 50585090': 99,
    'BES Investimento do Brasil – Banco de Investimento - 34111187': 100,
    'BM TRICURY - 57839805': 101,
    'BNY MELLON - 42272526': 102,
    'BPN Brasil Banco Múltiplo - 61033106': 103,
    'BRADESCO BERJ - 33147315': 104,
    'BRASIL PLURAL Banco Múltiplo - 45246410': 105,
    'BRB – Banco de Brasília - 00000208': 106,
    'BRICKELL Crédito, Financiamento e Investimento - 12865507': 107,
    'CAIXA ECONOMICA FEDERAL - 00360305': 108,
    'CC CREDICOAMO Crédito Rural Cooperativa - 81723108': 109,
    'CC UNICRED Brasil Central - 04243780': 110,
    'CC UNICRED do Brasil - 00315557': 111,
    'CC UNIPRIME NORTE do PARANA - 02398976': 112,
    'CECOOPES – Central das Cooperativas de Economia e Crédito Mútuo - 05790149': 113,
    'CITIBANK N.A. - 33042953': 114,
    'Cooperativa Central de Crédito do Estado de SP - 73085573': 115,
    'Cooperativa Central de Crédito NOROESTE Brasileiro - 04632856': 116,
    'Cooperativa Central de Crédito Urbano – CECRED - 05463212': 117,
    'Cooperativa de Crédito Rural da Região da Mogiana - 62109566': 118,
    'CREDIALIANCA Cooperativa de Crédito RURAL - 78157146': 119,
    'DEUTSCHE BANK – Banco ALEMÃO - 62331228': 120,
    'HIPERCARD Banco Múltiplo - 03012230': 121,
    'HSBC BANK Brasil – Banco Múltiplo - 01701201': 122,
    'ICBC do Brasil Banco Múltiplo - 17453575': 123,
    'ING BANK N.V. - 49336860': 124,
    'ITAÚ UNIBANCO - 60701190': 125,
    'NOVO Banco CONTINENTAL - 74828799': 126,
    'PARANA Banco - 14388334': 127,
    'SCOTIABANK Brasil Banco Múltiplo - 29030467': 128,
    'UNICRED Central RS – Central de Cooperativa de Economia e Crédito Mútuo do Estado do RS - 01634601': 129,
    'UNICRED Central Santa Catarina - 00543968': 130,
    'UNIPRIME Central – Central Interestadual de Cooperativa de Crédito - 03046391': 131,
    'MONEY PLUS - 11581339': 132,
};

export default Object.entries(banks).map(([title, bank]) => ({
    title,
    bank,
})).sort((a, b) => a.title.localeCompare(b.title));
