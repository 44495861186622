<template>
  <b-card>
    <b-modal
      id="modal-scrollable"
      ref="liquidated-modal"
      size="md"
      scrollable
      title="Liquidação Manual"
      hide-footer
    >
      <manual-liquidation
        v-if="selectedManualLiquidation"
        :manual-liquidation="selectedManualLiquidation"
        :register="register"
      />
    </b-modal>

    <div
      class="mt-2"
    >

      <b-row
        v-if="!hideControls"
        class="justify-content-between my-2"
      >
        <b-col
          v-if="!hideControls"
          md="6"
        >
          <download-base />
        </b-col>

        <b-col
          v-if="!hideControls"
          class="d-flex"
          md="6"
        >
          <b-button
            v-b-modal="'generate-urs'"
            variant="primary"
            class="mb-1 ml-auto"
          >
            Gerar URs Automaticamente
          </b-button>
        </b-col>

        <b-col
          v-if="!hideControls"
          md="3"
        >
          <b-form-file
            id="csvfile"
            v-model="file"
            class="mb-2 btn-file"
            style="display: none"
          />

          <b-row class="align-items-center">

            <b-button
              class="d-block m-1"
              variant="primary"
              @click="importExcel"
            >
              Importar Excel
            </b-button>

            <feather-icon
              icon="HelpCircleIcon"
              size="21"
              class="my-1"
              @mouseover="onOver"
              @mouseleave="onLeave"
            />

            <b-dropdown
              id="dropdown-1"
              ref="dropdown"
              class="contract-dropdown"
              style="width: 400px; height: 0"
            >
              <instructions class="p-1" />
            </b-dropdown>

          </b-row>

          <import-file
            ref="importCsv"
            :is-percentage="isPercentage"
            :schemes="schemes"
          />
        </b-col>
      </b-row>
    </div>

    <b-modal
      v-if="!hideControls"
      id="generate-urs"
      ref="generate-urs"
      size="md"
      scrollable
      hide-footer
      title="Gerar URs Automaticamente"
    >
      <generate-urs
        :payment-schemes="schemesOptions"
        :contract-specifications="contractSpecifications"
        @generate="generateURs"
      />

    </b-modal>

    <validation-observer
      ref="contractValidation"
      #default="{ invalid }"
    >
      <table-contract-specifications
        :fields="fields"
        :register="register"
        :is-percentage="isPercentage"
        :total-contract-specification-pages="totalContractSpecificationPages"
        :contract-specifications="contractSpecifications"
        :schemes="schemes"
        :effect-type="effectType"
        :money-mask="money"
        :page-requested="page"
        @change-page="page => $emit('change-page', page)"
        @manual-liquidatation="setManualLiquidationItem"
      />

      <b-row
        v-if="!hideControls"
        style="gap: 12px;"
        class="justify-content-center"
      >
        <b-button
          variant="primary"
          class="mt-3"
          :disabled="invalid"
          @click="addContractSpecifications"
        >
          Adicionar Obrigação de Contrato
        </b-button>

        <b-button
          v-if="!isEdit"
          variant="danger"
          class="mt-3"
          :disabled="invalid"
          @click="() => $emit('reset')"
        >
          Apagar todas as URs
        </b-button>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import {
    BCol,
    BButton,
    BCard,
    BFormFile,
    BRow,
    BDropdown, BDropdownItem,
    VBTooltip,
} from 'bootstrap-vue';

import {
    ValidationObserver,
} from 'vee-validate';

import schemes from '@/utils/schemes';

import GenerateUrs from '../GenerateURs.vue';
import TableContractSpecifications from './Table.vue';
import ImportFile from './File/Import.vue';
import Instructions from './File/Instructions.vue';
import DownloadBase from './File/DownloadBase.vue';
import ManualLiquidation from './ManualLiquidation/ManualLiquidation.vue';

const removeSymbolsMoney = value => (typeof value === 'string'
    ? +value.replace(/[R$]/g, '').replace(',', '.')
    : value);

export default {
    components: {
        BCol,
        BButton,
        ImportFile,
        BFormFile,
        BCard,
        GenerateUrs,
        BRow,
        TableContractSpecifications,
        ManualLiquidation,
        ValidationObserver,
        Instructions,
        BDropdownItem,
        DownloadBase,
        BDropdown,
    },

    directives: {
        'b-tooltip': VBTooltip,
    },

    props: {
        contractSpecifications: {
            type: Array,
            default: () => [],
        },

        effectType: {
            type: Number,
            default: 0,
        },

        hideControls: {
            type: Boolean,
            default: false,
        },

        totalContractSpecificationPages: {
            type: Number,
            default: () => 0,
        },

        register: {
            type: String,
            default: '',
        },

        isPercentage: {
            type: Boolean,
            default: () => false,
        },

        fields: {
            type: Array,
            default: () => [],
        },

        isEdit: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            page: 1,
            file: null,
            schemes,
            selectedManualLiquidation: {
                item: {
                    id: 0,
                    expected_settlement_date: '2022-01-01',
                    receivable_debtor: '00000000000',
                    original_asset_holder: '00000000000',
                    effect_value: 0,
                    paid_value: 0,
                },
            },

            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: false,
            },
        };
    },

    computed: {
        taxId() {
            return ['###.###.###-##', '##.###.###/####-##'];
        },

        schemesOptions() {
            const options = schemes.map(scheme => ({
                text: scheme,
                value: scheme,
            }));
            options.unshift({
                text: 'Todas',
                value: 'all',
            });
            return options;
        },
    },

    watch: {
        file() {
            const input = document.getElementById('csvfile');

            if (!this.file) return;
            const reader = new FileReader();

            reader.onload = async () => {
                // eslint-disable-next-line no-useless-escape
                const fileInArray = reader.result.split(/[\r,\n, ';', '\', "\"" ]+/)
                    .filter(item => item);

                const contractSpecificationImported = await this.$refs.importCsv.import(fileInArray);
                this.$emit('import', contractSpecificationImported);
            };

            reader.readAsText(input.files[0]);
        },
    },

    methods: {
        addContractSpecifications() {
            this.page = 1;
            let countEditable = 0;
            // eslint-disable-next-line no-restricted-syntax
            for (const contractSpecification of this.contractSpecifications) {
                if (!contractSpecification.payment_scheme) countEditable++;
                if (countEditable >= 1) {
                    this.page = Math.floor(this.contractSpecifications.length / 10) + 1;
                    this.modalError('Você possui especificações de contrato faltando preenchimento');
                    return;
                }

                contractSpecification.is_editable = false;
            }

            this.contractSpecifications.push({
                is_possible_edit: true,
                is_editable: true,
                original_asset_holder: this.$store.getters['creditPlatform/merchant'].asset_holder,
                expected_settlement_date: '',
                receivable_debtor: '',
                payment_scheme: '',
                effect_value: this.isPercentage ? 'R$ 0.00' : 'R$ 1.00',

            });
            this.page = Math.floor(this.contractSpecifications.length / 10) + 1;
        },

        removeContractSpecification({ item }) {
            const index = this.contractSpecifications.findIndex(item2 => item === item2);

            this.contractSpecifications.splice(index, 1);
        },

        importExcel() {
            this.file = null;
            document.getElementById('csvfile').click();
        },

        showModal() {
            this.$refs['liquidated-modal'].show();
        },

        hideModal() {
            this.$refs['liquidated-modal'].hide();
        },

        removeSymbols(item) {
            return removeSymbolsMoney(item);
        },

        generateURs(data) {
            if (!data.group && !this.edit) {
                this.contractSpecifications.length = 0;
            }

            data.data.forEach(item => {
                this.contractSpecifications.push(item);
            });

            this.$refs['generate-urs'].hide();
        },

        setManualLiquidationItem(item) {
            this.selectedManualLiquidation = { item };
        },

        onOver() {
            this.$refs.dropdown.visible = true;
        },
        onLeave() {
            this.$refs.dropdown.visible = false;
        },
    },

};
</script>

<style lang="scss">
.contract-dropdown {
    .btn {
        visibility: hidden;
        width: 0;
    }
}
table {
  input {
    min-width: 200px !important;
  }

  small {
    text-align: left !important;
  }

  .clickable {
    transition: all 0.2s ease-in-out;
    &:hover {
      cursor: pointer;
    }

    &.edit {
      color: #2E2C6C;
      &:hover {
        color: #F37021;
      }
    }

    &.confirm {
      color: #28c76f;
      &:hover {
        color: #339933;
      }
    }

    &.remove {
      color: #ea5455;
      &:hover {
        color: #ff9f43;
      }
    }
  }
}
</style>
