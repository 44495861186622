import axios from 'axios';

const token = '2924|hta7UXuY1jG6LBNnhYu5PLicSrOsYpNg';

class HolidayService {
    api = axios.create({
        baseURL: 'https://api.invertexto.com/v1/',
    })

    async getHolidaysByYear(year, state = 'sp') {
        try {
            return await this.api.get(`holidays/${year}`, {
                params: {
                    token,
                    state,
                },
            });
        } catch ({ response }) {
            return response;
        }
    }
}

export default new HolidayService();
