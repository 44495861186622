var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CreditPlatformItemProductLayout',{attrs:{"merchant-selected-is-required":false,"optin-selected-is-required":false,"merchant":_vm.endorsement.merchant,"breadcrumb-items":[
    {
      text: 'Inicio',
      href: '/plataforma-credito',
    },
    {
      text: 'Detalhes Estabelecimento',
      href: ("/plataforma-credito/estabelecimento/detalhes/" + (_vm.endorsement.merchant.id)),
    },
    {
      text: 'Averbação',
    } ]}},[_c('b-card',[_c('validation-observer',{ref:"averbationForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var invalid = ref.invalid;
return [_c('form-averbation',{attrs:{"disabled-fields":"","register":_vm.endorsement.register,"endorsement":_vm.endorsement}}),_c('h3',[_vm._v(" URs já cadastradas ")]),_c('contract-specifications',{attrs:{"is-percentage":_vm.endorsement.division_method === 'percentage',"contract-specifications":_vm.endorsement.contract_specifications,"total-contract-specification-pages":_vm.totalContractSpecificationPages,"is-edit":true,"hide-controls":true,"effect-type":_vm.endorsement.effect_type_id,"register":_vm.endorsement.register,"fields":[
          { key: 'id', label: 'id' },
          { key: 'expected_settlement_date', label: 'previsão da liquidação', sortable: true, },
          { key: 'receivable_debtor', label: 'adquirente' },
          { key: 'payment_scheme', label: 'esquema' },
          { key: 'effect_value', label: 'Valor Averbado', sortable: true, },
          { key: 'effect_priority', label: 'Posição na Fila', sortable: true, },
          { key: 'total_value', label: 'Valor total da UR', sortable: true, },
          { key: 'charge_value', label: 'Valor Alcançado', sortable: true, },
          { key: 'time', label: 'Prazo' },
          { key: 'actions', label: 'Ações' } ]},on:{"change-page":function (page) { return _vm.$emit('change-page', page); },"import":_vm.handleImportedCsv}}),_c('h3',[_vm._v(" Novas URs ")]),_c('contract-specifications',{staticClass:"mb-2",attrs:{"is-percentage":_vm.endorsement.division_method === 'percentage',"fields":_vm.contractSpecificationFields,"effect-type":_vm.endorsement.effect_type_id,"register":_vm.endorsement.register,"total-contract-specification-pages":_vm.totalContractSpecificationPages,"contract-specifications":_vm.newContractSpecifications},on:{"import":_vm.handleImportedCsv}}),_c('b-row',{staticClass:"justify-content-center",staticStyle:{"gap":"12px"}},[_c('b-button',{attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.validateAverbation}},[_vm._v(" Salvar Averbação ")]),_c('b-button',{attrs:{"variant":"danger","disabled":_vm.endorsement.status_id === 1},on:{"click":_vm.cancelEndorsement}},[_vm._v(" Cancelar Averbação ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.downloadEndorsement}},[_vm._v(" Baixar Averbação ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }