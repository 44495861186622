<template>
  <b-container fluid>
    <BreadCrumb
      :items="breadcrumbItems"
    />

    <MerchantHeader
      :merchant-data="{
        name: merchantData.legal_tax_name,
        tax_id: merchantData.asset_holder
      }"
    />
    <slot />
  </b-container>
</template>

<script>
import { BContainer } from 'bootstrap-vue';

import MerchantHeader from '@/views/pages/CreditPlatform/Merchants/Details/components/Header.vue';

import BreadCrumb from '../components/BreadCrumb.vue';

export default {
    components: { BContainer, MerchantHeader, BreadCrumb },
    props: {
        merchant: {
            require: false,
            default: () => ({}),
            type: Object,
        },

        merchantSelectedIsRequired: {
            default: () => true,
            type: Boolean,
        },

        optinSelectedIsRequired: {
            default: () => true,
            type: Boolean,
        },

        breadcrumbItems: {
            default: () => [],
            type: Array,
        },
    },

    data() {
        return {
            merchantData: null,
        };
    },

    created() {
        const storedMerchant = this.getStoreMerchant();

        const merchant = this.objectIsEmpty(this.merchant) ? storedMerchant : this.merchant;

        if (this.merchantSelectedIsRequired && !storedMerchant) this.$router.push('/home');

        if (this.optinSelectedIsRequired && !Object.values(storedMerchant.optin_id).find(item => item)) {
            this.$router.push('/home');
        }

        if (!merchant.asset_holder) {
            merchant.asset_holder = merchant.tax_id;
        }

        this.merchantData = merchant;
    },

    methods: {
        objectIsEmpty(obj) {
            // eslint-disable-next-line no-restricted-syntax
            for (const prop in obj) {
                // eslint-disable-next-line no-prototype-builtins
                if (obj.hasOwnProperty(prop)) { return false; }
            }

            return true;
        },

        getStoreMerchant() {
            return this.$store.getters['creditPlatform/merchant'];
        },
    },
};
</script>
