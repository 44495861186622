export default Object.freeze({
    1: 'Troca de Titularidade',
    2: 'Ônus e Gravames',
    // 3: 'Promessa de Cessão',
    4: 'Penhora',
    5: 'Bloqueio judicial',
    6: 'Ônus - Cessão fiduciária',
    7: 'Ônus - Outros',
    8: 'Promessa de Cessão',
});
