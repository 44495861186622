<template>
  <b-card class="card-info">
    <h4> {{ title }} </h4>
    <slot />
  </b-card>
</template>

<script>
import {
    BCard,
} from 'bootstrap-vue';

export default {
    components: {
        BCard,
    },

    props: {
        title: {
            type: String,
            default: () => '',
        },
    },
};
</script>

<style lang="scss" scoped>
    .card {
        text-align: left;
        margin-bottom: 0 !important;
        box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
        height: 100px;
    }

    .dark-layout {
        .card {
            background-color: #161d31;
        }
    }

    p, span {
        font-size: 16px;
    }

    .alert {
        padding: .2rem .7rem;
        width: 5.1rem;
        margin: 0;
        border-radius: 4rem;
        display: flex;
        justify-content: center;
    }

    .btn {
        position: absolute;
        left: 100%;
        transform: translateX(-110%)
    }

    @media (max-width: 991px) {
      .card {
        margin-top: 1rem;
      }
    }

</style>
