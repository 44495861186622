<template>
  <b-card>
    <heading class="text-center">
      Contrato
    </heading>
    <validation-observer>
      <b-form @submit.prevent>
        <b-row>
          <b-col md="3">
            <b-form-group
              label="Data de Assinatura"
              label-for="signature_date"
            >
              <validation-provider
                #default="{ errors }"
                name="Data de Assinatura"
                rules="required|before-date"
              >
                <b-form-input
                  id="signature_date"
                  v-model="endorsement.signature_date"
                  :state="errors.length > 0 ? false : null"
                  type="date"
                  :disabled="disabledFields"
                />
                <small class="text-danger text-left">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Data de Vencimento do Contrato"
              label-for="expire_date"
            >
              <validation-provider
                #default="{ errors }"
                name="Data de Vencimento do Contrato"
                :rules="`required|${disabledFields ? '' : 'after-date'}`"
              >
                <b-form-input
                  id="expire_date"
                  v-model="endorsement.contract_due_date"
                  :state="errors.length > 0 ? false : null"
                  type="date"
                  :disabled="disabledFields"
                />
                <small class="text-danger text-left">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Beneficiário"
              label-for="beneficiary"
            >
              <validation-provider
                #default="{ errors }"
                name="Beneficiário"
                rules="required|tax-id"
              >
                <b-form-input
                  id="beneficiary"
                  v-model="endorsement.beneficiary"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  placeholder="Beneficiário"
                  :state="errors.length > 0 ? false : null"
                  :disabled="disabledFields"
                />
                <small class="text-danger text-left">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Saldo Devido"
              label-for="contract_value"
            >
              <validation-provider
                #default="{ errors }"
                name="Saldo Devido"
                rules="required"
              >
                <b-form-input
                  id="contract_value"
                  v-model="endorsement.contract_value"
                  v-money="money"
                  :state="errors.length > 0 ? false : null"
                  :disabled="disabledFields"
                />
                <small class="text-danger text-left">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="disabledFields"
            md="3"
          >
            <b-form-group
              label="Identificador do Contrato"
              label-for="contract_value"
            >
              <b-form-input
                v-model="endorsement.key"
                disabled
              />
            </b-form-group>

          </b-col>

        </b-row>

        <heading class="text-center my-2">
          Banco
        </heading>

        <b-row>
          <b-col md="3">
            <b-form-group
              label="Banco"
              label-for="bank"
            >
              <validation-provider
                #default="{ errors }"
                name="Banco"
                rules="required"
              >
                <v-select
                  id="bank"
                  v-model="endorsement.bank_account.bank"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="banks"
                  placeholder="Banco"
                  :class="errors.length > 0 ? 'error' : null"
                />
                <small class="text-danger text-left">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Tipo de Conta"
              label-for="type_account"
            >
              <b-form-select
                id="type_account"
                v-model="endorsement.bank_account.account_type"
                :options="[
                  { text: 'Conta Corrente', value: 'CC' },
                  { text: 'Conta Poupança', value: 'CP' },
                ]"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Nome do Titular"
              label-for="holder_name"
            >
              <validation-provider
                #default="{ errors }"
                name="Nome do Titular"
                rules="required"
              >
                <b-form-input
                  id="holder_name"
                  v-model="endorsement.bank_account.holder_name"
                  placeholder="Nome do Titular"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger text-left">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Agência"
              label-for="agency"
            >
              <validation-provider
                #default="{ errors }"
                name="Agência"
                rules="required|agency"
              >
                <b-form-input
                  id="agency"
                  v-model="endorsement.bank_account.agency"
                  v-mask="'####'"
                  placeholder="Agência"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger text-left">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Conta"
              label-for="account"
            >
              <validation-provider
                #default="{ errors }"
                name="Conta"
                rules="required|bank-account"
              >
                <b-form-input
                  id="account"
                  v-model="bankAccount"
                  placeholder="#####-#"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger text-left">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Documento do Titular"
              label-for="titular_tax_id"
            >
              <validation-provider
                #default="{ errors }"
                name="Documento do Titular"
                rules="required|tax-id"
              >
                <b-form-input
                  id="titular_tax_id"
                  v-model="endorsement.bank_account.document_number"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  placeholder="Documento do Titular"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger text-left">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <heading class="text-center my-2">
          Averbação
        </heading>

        <b-row>
          <b-col md="3">
            <b-form-group
              label="Metodo de Divisão"
              label-for="division-method"
            >
              <b-form-select
                id="division-method"
                v-model="endorsement.division_method"
                :disabled="hasExternalContractSpecifications || disabledFields"
                :options="[{ text: 'Fixo', value: 'fixed' }, { text: 'Porcentagem', value: 'percentage' }]"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-if="endorsement.division_method === 'percentage'"
            md="3"
          >
            <b-form-group
              label="Valor da porcentagem"
              label-for="percentage"
            >
              <validation-provider
                #default="{ errors }"
                name="Valor da Porcentagem"
                rules="required|percent"
              >
                <b-input-group append="%">
                  <b-form-input
                    id="percentage"
                    v-model="endorsement.percentage"
                    :state="errors.length > 0 ? false : null"
                    :disabled="disabledFields"
                  />
                </b-input-group>
                <small class="text-danger text-left">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="3"
          >
            <b-form-group
              label="Registradora"
              label-for="register"
            >
              <validation-provider
                #default="{ errors }"
                name="Registradora"
                rules="required"
              >
                <b-form-select
                  v-model="endorsement.register"
                  :disabled="disabledFields"
                  :options="registerOptions"
                />
                <small class="text-danger text-left">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="3"
          >
            <b-form-group
              label="Modalidade"
              label-for="percentage"
            >
              <validation-provider
                #default="{ errors }"
                name="Modalidade"
                rules="required"
              >
                <b-form-select
                  v-model="endorsement.effect_type"
                  :options="effectTypeOptions"
                  :disabled="disabledFields || !endorsement.register"
                />
                <small class="text-danger text-left">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormSelect,
    BInputGroup,
} from 'bootstrap-vue';

import {
    ValidationProvider,
    ValidationObserver,
} from 'vee-validate';

import VSelect from 'vue-select';

import Heading from '@/views/components/Heading.vue';

import banks from '@/utils/banks';

import '@validations';
import effectType from '@/enums/effectType';

export default {
    components: {
        BCard,
        BFormInput,
        BFormGroup,
        BForm,
        BFormSelect,
        BInputGroup,
        BRow,
        BCol,
        Heading,
        VSelect,
        ValidationProvider,
        ValidationObserver,
    },

    props: {
        endorsement: {
            type: Object,
            default: () => {},
        },

        disabledFields: {
            type: Boolean,
            default: () => false,
        },

        draft: {
            type: Boolean,
            default: () => false,
        },

        registerOptions: {
            type: Array,
            default: () => ([
                'TAG',
                'CERC',
            ]),
        },
        hasExternalContractSpecifications: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            banks,
            bankAccount: '',
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: false,
            },
        };
    },

    computed: {
        effectTypeOptions() {
            const effectTypesByRegister = {
                CERC: [1, 5, 6, 7, 8],
                TAG: [1, 2, 3, 4],
            };

            if (!this.endorsement.register) { return []; }

            return Object.entries(effectType).map(([key, item]) => ({
                value: +key,
                text: item,
            })).filter(item => effectTypesByRegister[this.endorsement.register].find(item2 => item2 === item.value));
        },
    },

    watch: {
        bankAccount(value) {
            const [accountNumber, accountDigit] = value.split('-');

            this.endorsement.bank_account = {
                ...this.endorsement.bank_account,
                account_number: accountNumber,
                account_digit: accountDigit,
            };
        },

        'endorsement.register': function () {
            this.endorsement.effect_type = 1;
        },
    },

    mounted() {
        if (this.disabledFields || this.draft) {
            const { account_number: accountNumber, account_digit: accountDigit } = this.endorsement.bank_account;
            this.bankAccount = `${accountNumber}-${accountDigit}`;
        }
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
