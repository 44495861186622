/* eslint-disable max-classes-per-file */
import taxId from '@/filters/taxId';
import { FileBuilderXlsx } from './builder';
import GenerateXlsx from './generate-xlsx';

const removeSymbolsMoney = value => (typeof value === 'string'
    ? +value.replace(/[R$ .]/g, '').replace(',', '.')
    : value);

function indexToColumn(index) {
    const columns = [
        'firstColumn',
        'secondColumn',
        'thirdColumn',
        'fourthColumn',
        'fifthColumn',
        'sixthColumn',
        'seventhColumn',
        'eighthColumn',
        'ninthColumn',
        'tenColumn',
        'elevenColumn',
        'twelveColumn',
        'thirteenthColumn',
        'fourteenthColumn',
        'fifteenthColumn',
        'sixteenthColumn',
        'seventeenthColumn',
        'eighteenthColumn',
        'nineteenthColumn',
        'twentiethColumn',
        'twentyFirstColumn',
        'twentySecondColumn',
        'twentyThirdColumn',
        'twentyFourthColumn',
        'twentyFifthColumn',
    ];

    return columns[index];
}

class Layout {
    layout = []

    constructor(
        layout,
    ) {
        this.layout = layout;
    }

    generate(rawData) {
        const dataFormatted = Array.isArray(rawData) ? rawData : [rawData];
        const headers = this.layout.reduce((acc, { name }, index) => {
            acc[indexToColumn(index)] = name;
            return acc;
        }, {});
        const data = dataFormatted.map(item => {
            const fileColumns = {};

            this.layout.forEach(({ key, formatter }, index) => {
                const keySplitted = key.split('.');

                const keyInObject = keySplitted.reduce((acc, objectKey) => acc[objectKey], item);
                fileColumns[indexToColumn(index)] = formatter ? formatter(keyInObject) : keyInObject;
            });

            return fileColumns;
        });

        return [headers, ...data];
    }
}

export default class EndorsementDownloader {
    xlsxGenerator = new GenerateXlsx()

    endorsementData

    layout = [
        { key: 'key', name: 'Identificador do Contrato' },
        { key: 'merchant_name', name: 'Estabelecimento' },
        { key: 'merchant_id', name: 'Id do Estabelecimento' },
        { key: 'establishment', name: 'CNPJ do Estabelecimento' },
        { key: 'beneficiary', name: 'Beneficiário' },
        { key: 'bank_account.bank', name: 'Banco' },
        { key: 'bank_account.agency', name: 'Agência' },
        { key: 'bank_account.holder_name', name: 'Titular' },
        { key: 'bank_account.account_type', name: 'Tipo da Conta' },
        { key: 'bank_account.account_number', name: 'Número da Conta' },
        { key: 'bank_account.account_digit', name: 'Dígito da Conta' },
        { key: 'bank_account.document_number', name: 'CNPJ do Titular da Conta' },
        { key: 'contract_value', name: 'Valor do Contrato', formatter: value => removeSymbolsMoney(value) },
        { key: 'signature_date', name: 'Data da Assinatura' },
        { key: 'division_method', name: 'Meotodo da Divisão', formatter: value => (value === 'percentage' ? 'Porcentagem' : 'Fixo') },
        { key: 'contract_due_date', name: 'Data de Vencimento' },
    ]

    layoutUrs = []

    constructor(
        data,
    ) {
        this.endorsementData = data;
    }

    do(layout, fileData) {
        const response = new Layout(layout).generate(fileData);
        const [headers, ...data] = response;

        this.xlsxGenerator.insertBoldLine(headers);
        data.forEach(item => this.xlsxGenerator.insertLine(item));
    }

    addGeneralData() {
        this.do(this.layout, this.endorsementData);
    }

    addContractSpecifications() {
        this.do(this.layoutUrs, this.endorsementData.contract_specifications);
    }

    async generate() {
        // eslint-disable-next-line no-unused-expressions
        this.layoutUrs = this.endorsementData.division_method === 'percentage' ? [
            { key: 'key', name: 'Identificador do Contrato' },
            { key: 'expected_settlement_date', name: 'Previsão da Liquidação' },
            { key: 'receivable_debtor', name: 'Adquirente', formatter: value => taxId(value) },
            { key: 'payment_scheme', name: 'Esquema' }, 
            { key: 'effect_priority', name: 'Posição na fila',formatter: value => (!value ? '-' : value) },
            { key: 'effect_value', name: 'Valor Averbado' ,formatter: value => (value ? value : '-')},   
            { key: 'total_value', name: 'VAlor total da UR',formatter: value => (value ? value : '-' )},
            { key: 'charge_value', name: 'Valor Alcançado' ,formatter: value => (value ? value : '-')},                  
            { key: 'time', name: 'Prazo' },
        ] : [
            { key: 'key', name: 'Identificador do Contrato' },
            { key: 'expected_settlement_date', name: 'Previsão da Liquidação' },
            { key: 'receivable_debtor', name: 'Adquirente', formatter: value => taxId(value) },
            { key: 'payment_scheme', name: 'Esquema' }, 
            { key: 'effect_priority', name: 'Posição na fila',formatter: value => (value == "" ? '-' : value)},
            { key: 'effect_value', name: 'Valor Averbado' },   
            { key: 'total_value', name: 'Valor total da UR' ,formatter: value => (value ? value : '-')},
            { key: 'charge_value', name: 'Valor Alcançado' ,formatter: value => (value ? value : '-')},            
            { key: 'time', name: 'Prazo' },
        ];
        console.log(this.generate);
        this.addGeneralData();
        this.xlsxGenerator.insertBlankLine();
        this.addContractSpecifications();

        const builder = new FileBuilderXlsx([...this.xlsxGenerator.fileContent], 'Averbação');

        await builder.build();
        builder.download();
    }
}
