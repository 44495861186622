<!-- eslint-disable vue/valid-template-root -->
<template />

<script>
import { validatorTaxId } from '@/@core/utils/validations/validators';

export default {
    props: {
        schemes: {
            type: Array,
            required: true,
        },

        isPercentage: {
            type: Boolean,
            default: () => false,
        },
    },

    methods: {
        async import(file) {
            this.isLoading(true);
            const headers = [
                'expected_settlement_date',
                'receivable_debtor',
                'payment_scheme',
                'effect_value',
            ];

            headers.forEach(() => {
                file.shift();
            });

            const contractSpecifications = [];

            let error = false;

            for (let i = 0; i < file.length; i += headers.length) {
                if (error) break;
                const [
                    expectedSettlementDate,
                    receivableDebtor,
                    paymentScheme,
                    effectValue,
                ] = (file.slice(i, i + headers.length));

                const [day, month, year] = expectedSettlementDate.split('/');
                const date = `${year}-${month}-${day}`;

                const settlementDateValidator = () => {
                    const dateToday = new Date();
                    const dateExpectedSettlement = new Date(date);
                    return (
                        dateToday.getTime() < dateExpectedSettlement.getTime() + 10800000
                    );
                };

                if (!settlementDateValidator()) {
                    this.modalError(`Data: ${expectedSettlementDate} inválida`);
                    error = true;
                }

                if (!validatorTaxId(receivableDebtor)) {
                    this.modalError(`Documento: ${receivableDebtor} inválido`);
                    error = true;
                }

                if (!this.schemes.includes(paymentScheme.toUpperCase())) {
                    this.modalError(`Esquema de pagamento ${paymentScheme} inválido`);
                    error = true;
                }

                if (effectValue < 1 && !this.isPercentage) {
                    this.modalError(
                        `Valor de efeito errado: ${effectValue}, deve ser maior que 1.00`,
                    );
                    error = true;
                }

                const effectValueinNumber = +effectValue;
                contractSpecifications.push({
                    expected_settlement_date: date,
                    receivable_debtor: receivableDebtor,
                    payment_scheme: paymentScheme,
                    effect_value: effectValueinNumber.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
                    is_possible_edit: true,
                    is_editable: false,
                });
            }

            this.isLoading(false);

            function haveDuplicate(arr) {
                for (let i = 0; i < arr.length; i += 1) {
                    for (let j = i + 1; j < arr.length; j += 1) {
                        if (arr[i].expected_settlement_date === arr[j].expected_settlement_date && arr[i].payment_scheme === arr[j].payment_scheme) { return arr[i]; }
                    }
                }

                return false;
            }
            const duplicateObjects = haveDuplicate(contractSpecifications);
            if (duplicateObjects) {
                this.modalError(`data duplicada: ${duplicateObjects.expected_settlement_date}`);
                error = true;
            }
            if (error) {
                return [];
            }
            return contractSpecifications;
        },
    },
};
</script>
