<template>
  <div>
    <h4>Como o CSV deve ser preenchido?</h4>
    <p
      v-for="(item, index) in instructions"
      :key="index"
    >
      {{ item }}
    </p>
  </div>
</template>

<script>
export default {
    data() {
        return {
            instructions: [
                'A Previsão da Liquidação deve ter esse formato dd/mm/aaaa, ex: 23/08/2022',
                'CNPJ Adquirente deve ter esse formato ##.###.###/####-##, ex: 22.036.090/0001-03',
                'Valor Efetivo deve ter esse formato ##.##, ex: 43.90',
                'Se o metódo de divisão for "Porcentagem" todos os valores efetivos devem estar preenchido com 0',
            ],
        };
    },
};
</script>
