import XlsxPopulate from 'xlsx-populate';

export class FileBuilderXlsx {
    data

    blob

    constructor(data, fileName = 'dados') {
        this.data = data;
        this.fileName = `${fileName}.xlsx`;
    }

    async build() {
        const workbook = await XlsxPopulate.fromBlankAsync();
        workbook.addSheet('URs');
        workbook.deleteSheet('Sheet1');
        const sheet = workbook.sheet('URs');

        this.data.forEach((row, rowIndex) => {
            Object.keys(row).forEach((colName, colIndex) => {
                if (row[colName]) {
                    const cell = sheet.cell(rowIndex + 1, colIndex + 1);
                    if (typeof row[colName] !== 'object') {
                        cell.value(row[colName]);
                    }

                    if (typeof row[colName] === 'number' && row[colName] < 0) {
                        cell.style({
                            fontColor: '900020',
                        });
                    }
                }

                sheet.row(rowIndex + 1).style('fontFamily', 'Calibri');
                sheet.row(rowIndex + 1).style('fontSize', 10);

                sheet.column('A').width(30);
                sheet.column('B').width(30);
                sheet.column('C').width(30);
                sheet.column('D').width(30);
                sheet.column('E').width(30);
                sheet.column('F').width(30);
                sheet.column('G').width(30);
                sheet.column('H').width(30);
                sheet.column('I').width(30);
                sheet.column('J').width(30);
                sheet.column('K').width(30);
                sheet.column('L').width(30);
                sheet.column('M').width(30);
                sheet.column('N').width(30);

                if (row.style) {
                    Object.entries(row.style).forEach(([key, value]) => sheet.row(rowIndex + 1).style(key, value));
                }
            });
        });

        this.blob = await workbook.outputAsync();

        return this;
    }

    download() {
        const blobURL = URL.createObjectURL(this.blob);

        const link = document.createElement('a');
        link.href = blobURL;
        link.download = this.fileName;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
