import OptInGetterByRegister from '@/register/get-optin';

export default class TagMapper {
    static map(data, isEdit) {
        const tag = {
            ...data,
        };
        if (!isEdit) {
            const optIn = new OptInGetterByRegister().get('tag');
            if (optIn) {
                tag.opt_in_id = optIn;
            }
        }

        return tag;
    }

    static toViewEndorsements(endorsementSpecifications) {
        return endorsementSpecifications.map(item => ({
            ...item,
            expected_settlement_date: item.expected_settlement_date,
            effect_value: item.effect_value,
            receivable_debtor: item.receivable_debtor,
            payment_scheme: item.payment_scheme.code,
            id: item.id,
            manual_liquidated: item.manual_liquidated,
            paid_value: item.paid_value,
            editable: false,
            is_possible_edit: false,
        }));
    }

    static toView(data) {
        return {
            ...data,
            register: 'TAG',
            contract_value: `${data.contract_value.toFixed(2)}`,
            effect_type: data.effect_type_id,
            bank_account: {
                ...data.account,
                bank: data.account.isbp.name,
            },
            endorsement_specifications: undefined,
            contract_specifications: data.endorsement_specifications.map(item => ({
                ...item,
                expected_settlement_date: item.expected_settlement_date,
                effect_value: item.effect_value,
                receivable_debtor: item.receivable_debtor,
                payment_scheme: item.payment_scheme.code,
                id: item.id,
                manual_liquidated: item.manual_liquidated,
                paid_value: item.paid_value,
                editable: false,
                is_possible_edit: false,
            })),
        };
    }
}
