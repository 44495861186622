<template>
  <b-card v-if="merchantData">
    <b-row class="justify-content-center">
      <b-col
        md="3"
        :lg="merchantData.active !== undefined ? 3 : 6"
      >
        <card-header :title="merchantData.tax_id.length === 11 ? 'CPF' : 'CNPJ'">
          <p> {{ merchantData.tax_id | tax_id }} </p>
        </card-header>
      </b-col>

      <b-col
        md="3"
        :lg="merchantData.active !== undefined ? 4 : 6"
      >
        <card-header title="Razão Social">
          <p> {{ merchantData.name }} </p>
        </card-header>
      </b-col>

      <b-col
        md="6"
        lg="3"
      >
        <card-header
          v-if="merchantData.active !== undefined"
          title="Status"
        >
          <b-alert
            show
            :variant="merchantData.active ? 'success' : 'secondary'"
            :style="merchantData.active ? 'width: 5.1rem' : 'width: 6.32rem'"
            pill
          >
            <span> {{ merchantData.active ? 'ATIVO' : 'INATIVO' }} </span>
          </b-alert>
        </card-header>
      </b-col>

      <b-link
        v-if="merchantData.active !== undefined"
        class="btn btn-primary"
        style="top: 70%"
        :to="{ name: 'credit-platform-merchant-edit' }"
      >
        Editar
      </b-link>

      <b-button
        v-if="merchantData.active !== undefined"
        variant="primary"
        style="top :35%"
        @click="() => $emit('click-historic-button')"
      >
        Histórico
      </b-button>

    </b-row>

  </b-card>
</template>

<script>
import {
    BCard, BRow, BCol, BAlert, BLink, BButton, BTable,
} from 'bootstrap-vue';

import CardHeader from './CardHeader.vue';

export default {
    components: {
        BAlert,
        BCard,
        BRow,
        BCol,
        BLink,
        BButton,
        CardHeader,
        BTable,
    },
    props: {
        merchantData: {
            type: Object,
            default: () => ({}),
        },
    },

    created() {
        const merchant = this.$store.getters['creditPlatform/merchant'];

        if (!this.merchantData.name) {
            this.merchantData.name = merchant.legal_tax_name;
            this.merchantData.tax_id = merchant.asset_holder;
        }
    },
};
</script>

<style lang="scss" scoped>
    .btn {
        position: absolute;
        left: 100%;
        width:108px;
        top: 50%;
        transform: translateX(-110%) translateY(-50%)
    }
    @media (max-width: 991px) {
      .btn {
        transform: none;
        position: static;
        left: 0;
        margin-top: 1rem;
      }
    }

</style>
