var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('heading',{staticClass:"text-center"},[_vm._v(" Contrato ")]),_c('validation-observer',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Data de Assinatura","label-for":"signature_date"}},[_c('validation-provider',{attrs:{"name":"Data de Assinatura","rules":"required|before-date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"signature_date","state":errors.length > 0 ? false : null,"type":"date","disabled":_vm.disabledFields},model:{value:(_vm.endorsement.signature_date),callback:function ($$v) {_vm.$set(_vm.endorsement, "signature_date", $$v)},expression:"endorsement.signature_date"}}),_c('small',{staticClass:"text-danger text-left"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Data de Vencimento do Contrato","label-for":"expire_date"}},[_c('validation-provider',{attrs:{"name":"Data de Vencimento do Contrato","rules":("required|" + (_vm.disabledFields ? '' : 'after-date'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"expire_date","state":errors.length > 0 ? false : null,"type":"date","disabled":_vm.disabledFields},model:{value:(_vm.endorsement.contract_due_date),callback:function ($$v) {_vm.$set(_vm.endorsement, "contract_due_date", $$v)},expression:"endorsement.contract_due_date"}}),_c('small',{staticClass:"text-danger text-left"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Beneficiário","label-for":"beneficiary"}},[_c('validation-provider',{attrs:{"name":"Beneficiário","rules":"required|tax-id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##', '##.###.###/####-##']),expression:"['###.###.###-##', '##.###.###/####-##']"}],attrs:{"id":"beneficiary","placeholder":"Beneficiário","state":errors.length > 0 ? false : null,"disabled":_vm.disabledFields},model:{value:(_vm.endorsement.beneficiary),callback:function ($$v) {_vm.$set(_vm.endorsement, "beneficiary", $$v)},expression:"endorsement.beneficiary"}}),_c('small',{staticClass:"text-danger text-left"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Saldo Devido","label-for":"contract_value"}},[_c('validation-provider',{attrs:{"name":"Saldo Devido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],attrs:{"id":"contract_value","state":errors.length > 0 ? false : null,"disabled":_vm.disabledFields},model:{value:(_vm.endorsement.contract_value),callback:function ($$v) {_vm.$set(_vm.endorsement, "contract_value", $$v)},expression:"endorsement.contract_value"}}),_c('small',{staticClass:"text-danger text-left"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.disabledFields)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Identificador do Contrato","label-for":"contract_value"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.endorsement.key),callback:function ($$v) {_vm.$set(_vm.endorsement, "key", $$v)},expression:"endorsement.key"}})],1)],1):_vm._e()],1),_c('heading',{staticClass:"text-center my-2"},[_vm._v(" Banco ")]),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Banco","label-for":"bank"}},[_c('validation-provider',{attrs:{"name":"Banco","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'error' : null,attrs:{"id":"bank","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.banks,"placeholder":"Banco"},model:{value:(_vm.endorsement.bank_account.bank),callback:function ($$v) {_vm.$set(_vm.endorsement.bank_account, "bank", $$v)},expression:"endorsement.bank_account.bank"}}),_c('small',{staticClass:"text-danger text-left"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Tipo de Conta","label-for":"type_account"}},[_c('b-form-select',{attrs:{"id":"type_account","options":[
                { text: 'Conta Corrente', value: 'CC' },
                { text: 'Conta Poupança', value: 'CP' } ]},model:{value:(_vm.endorsement.bank_account.account_type),callback:function ($$v) {_vm.$set(_vm.endorsement.bank_account, "account_type", $$v)},expression:"endorsement.bank_account.account_type"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Nome do Titular","label-for":"holder_name"}},[_c('validation-provider',{attrs:{"name":"Nome do Titular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"holder_name","placeholder":"Nome do Titular","state":errors.length > 0 ? false : null},model:{value:(_vm.endorsement.bank_account.holder_name),callback:function ($$v) {_vm.$set(_vm.endorsement.bank_account, "holder_name", $$v)},expression:"endorsement.bank_account.holder_name"}}),_c('small',{staticClass:"text-danger text-left"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Agência","label-for":"agency"}},[_c('validation-provider',{attrs:{"name":"Agência","rules":"required|agency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],attrs:{"id":"agency","placeholder":"Agência","state":errors.length > 0 ? false : null},model:{value:(_vm.endorsement.bank_account.agency),callback:function ($$v) {_vm.$set(_vm.endorsement.bank_account, "agency", $$v)},expression:"endorsement.bank_account.agency"}}),_c('small',{staticClass:"text-danger text-left"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Conta","label-for":"account"}},[_c('validation-provider',{attrs:{"name":"Conta","rules":"required|bank-account"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"account","placeholder":"#####-#","state":errors.length > 0 ? false : null},model:{value:(_vm.bankAccount),callback:function ($$v) {_vm.bankAccount=$$v},expression:"bankAccount"}}),_c('small',{staticClass:"text-danger text-left"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Documento do Titular","label-for":"titular_tax_id"}},[_c('validation-provider',{attrs:{"name":"Documento do Titular","rules":"required|tax-id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##', '##.###.###/####-##']),expression:"['###.###.###-##', '##.###.###/####-##']"}],attrs:{"id":"titular_tax_id","placeholder":"Documento do Titular","state":errors.length > 0 ? false : null},model:{value:(_vm.endorsement.bank_account.document_number),callback:function ($$v) {_vm.$set(_vm.endorsement.bank_account, "document_number", $$v)},expression:"endorsement.bank_account.document_number"}}),_c('small',{staticClass:"text-danger text-left"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('heading',{staticClass:"text-center my-2"},[_vm._v(" Averbação ")]),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Metodo de Divisão","label-for":"division-method"}},[_c('b-form-select',{attrs:{"id":"division-method","disabled":_vm.hasExternalContractSpecifications || _vm.disabledFields,"options":[{ text: 'Fixo', value: 'fixed' }, { text: 'Porcentagem', value: 'percentage' }]},model:{value:(_vm.endorsement.division_method),callback:function ($$v) {_vm.$set(_vm.endorsement, "division_method", $$v)},expression:"endorsement.division_method"}})],1)],1),(_vm.endorsement.division_method === 'percentage')?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Valor da porcentagem","label-for":"percentage"}},[_c('validation-provider',{attrs:{"name":"Valor da Porcentagem","rules":"required|percent"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"id":"percentage","state":errors.length > 0 ? false : null,"disabled":_vm.disabledFields},model:{value:(_vm.endorsement.percentage),callback:function ($$v) {_vm.$set(_vm.endorsement, "percentage", $$v)},expression:"endorsement.percentage"}})],1),_c('small',{staticClass:"text-danger text-left"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2062164207)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Registradora","label-for":"register"}},[_c('validation-provider',{attrs:{"name":"Registradora","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":_vm.disabledFields,"options":_vm.registerOptions},model:{value:(_vm.endorsement.register),callback:function ($$v) {_vm.$set(_vm.endorsement, "register", $$v)},expression:"endorsement.register"}}),_c('small',{staticClass:"text-danger text-left"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Modalidade","label-for":"percentage"}},[_c('validation-provider',{attrs:{"name":"Modalidade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.effectTypeOptions,"disabled":_vm.disabledFields || !_vm.endorsement.register},model:{value:(_vm.endorsement.effect_type),callback:function ($$v) {_vm.$set(_vm.endorsement, "effect_type", $$v)},expression:"endorsement.effect_type"}}),_c('small',{staticClass:"text-danger text-left"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }