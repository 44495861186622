<template>
  <div>
    <validation-observer
      ref="contractValidation"
    >
      <b-table
        :current-page="totalContractSpecificationPages ? 1 : page"
        class="text-center"
        :per-page="10"
        :items="contractSpecifications"
        responsive
        :fields="fields"
      >
        <template #cell(expected_settlement_date)="data">
          <span v-if="!data.item.is_editable">
            {{ data.item.expected_settlement_date | date }}
          </span>

          <validation-provider
            v-else
            #default="{ errors }"
            name="Previsão da Liquidação"
            rules="required|after-today"
          >
            <b-form-input
              v-model="data.item.expected_settlement_date"
              type="date"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger text-left">{{ errors[0] }}</small>
          </validation-provider>
        </template>

        <template #cell(receivable_debtor)="data">
          <span v-if="!data.item.is_editable">
            {{ data.item.receivable_debtor | tax_id }}
          </span>

          <validation-provider
            v-else
            #default="{ errors }"
            name="CNPJ ADQUIRENTE"
            rules="required|tax-id"
          >
            <b-form-input
              v-model="data.item.receivable_debtor"
              v-mask="['###.###.###-##', '##.###.###/####-##']"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger text-left">{{ errors[0] }}</small>
          </validation-provider>
        </template>

        <template #cell(payment_scheme)="data">
          <span v-if="!data.item.is_editable">
            {{ data.item.payment_scheme }}
          </span>

          <validation-provider
            v-else
            #default="{ errors }"
            name="Esquema"
            rules="required"
          >
            <b-form-select
              id="type_account"
              v-model="data.item.payment_scheme"
              :options="schemesOptions"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger text-left">{{ errors[0] }}</small>
          </validation-provider>
        </template>

        <template #cell(effect_value)="data">
          <span v-if="!data.item.is_editable && data.item.division_method !== 'percentage'">
            <span v-if="(typeof data.item.effect_value === 'string')">{{ data.item.effect_value }}</span>
            <span v-else> {{ data.item.effect_value | value }}</span>
          </span>

          <span v-else-if="!data.item.is_editable && data.item.division_method === 'percentage'">
            {{ 0 | value }}
          </span>

          <validation-provider
            v-else
            #default="{ errors }"
            name="Valor Efetivo"
            :rules="`required|${isPercentage ? '' : 'effective-value'}`"
          >

            <b-form-input
              v-model="data.item.effect_value"
              v-money="moneyMask"
              :disabled="isPercentage"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger text-left">{{ errors[0] }}</small>
          </validation-provider>
        </template>

        <template #cell(actions)="data">
          <div v-if="(data.item.is_possible_edit)">
            <feather-icon
              :icon="data.item.is_editable ? 'CheckIcon' : 'Edit2Icon'"
              size="18"
              class="clickable"
              :class="data.item.editable ? 'confirm' : 'edit'"
              @click="editContractSpecification(data)"
            />

            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="clickable remove"
              @click="removeContractSpecification(data)"
            />
          </div>

          <div v-else>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <!-- <b-dropdown-item
                v-if="!data.item.editable && data.item.division_method !== 'percentage'"
                v-b-modal="'modal-scrollable'"
                @click="handleManualLiquidationDropdownClick(data.item)"
              >
                <feather-icon
                  icon="SidebarIcon"
                  size="18"
                />
                <span> Fazer Liquidação Manual </span>
              </b-dropdown-item> -->
            </b-dropdown>
          </div>
        </template>

        <template #cell(paid_value)="data">

          <span v-if="!data.item.is_possible_edit">
            {{ data.item.paid_value | value }}
          </span>

          <span v-else> ---- </span>
        </template>

        <template #cell(open_balance)="data">
          <span v-if="!data.item.is_possible_edit && data.item.division_method !== 'percentage'">
            {{
              data.item.open_balance
                | value
            }}
          </span>

          <span v-else> ---- </span>
        </template>

        <template #cell(manual_posting)="data">
          <span v-if="!data.item.is_possible_edit">
            {{ data.item.manual_posting || 0 | value }}
          </span>

          <span v-else> ---- </span>
        </template>

        <template #cell(effect_priority)="data">
          {{ data.item.effect_priority ? data.item.effect_priority : '-' }}
        </template>

        <template #cell(charge_value)="data">
          {{ data.item.charge_value ? data.item.charge_value : '-' }}
        </template>

        <template #cell(total_value)="data">
          <p v-if="data.item.total_value">
            {{ data.item.total_value | value }}
          </p>
          <p v-else>
            -
          </p>
        </template>

        <template #cell(time)="data">
          {{ todayDate > data.item.expected_settlement_date ? 'Expirado' : 'A Expirar' }}
        </template>
      </b-table>
    </validation-observer>
    <b-pagination
      :key="counterPagination"
      v-model="page"
      :total-rows="totalContractSpecificationPages || contractSpecifications.length"
      :per-page="10"
      align="center"
      size="sm"
      class="my-0"
    />

  </div>
</template>

<script>
import {
    BPagination, BTable, BBadge, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { DateTime } from 'luxon';
import effectTypeEnum from '@/enums/effectType';

export default {
    components: {
        BPagination, BTable, BBadge, ValidationProvider, BFormInput, BFormSelect, BDropdown, BDropdownItem, ValidationObserver,
    },

    props: {
        contractSpecifications: {
            type: Array,
            required: true,
        },

        totalContractSpecificationPages: {
            type: Number,
            default: () => 0,
        },

        pageRequested: {
            type: Number,
            default: () => 0,
        },

        fields: {
            type: Array,
            default: () => [],
        },

        isPercentage: {
            type: Boolean,
            default: () => false,
        },

        schemes: {
            type: Array,
            required: true,
        },

        moneyMask: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            page: 1,
            effectTypeEnum,
            counterPagination: 0,
        };
    },

    computed: {
        schemesOptions() {
            return this.schemes.map(scheme => ({
                text: scheme,
                value: scheme,
            }));
        },

        todayDate() {
            return DateTime.now().toFormat('yyyy-MM-dd');
        },
    },

    watch: {
        pageRequested(value) {
            this.counterPagination += 1;
            this.page = value || 1;
        },

        page(value) {
            this.$emit('change-page', value);
        },
    },

    methods: {
        editContractSpecification({ item }) {
            this.$refs.contractValidation.validate().then(success => {
                const index = this.contractSpecifications.findIndex(item2 => item === item2);
                if (success) { this.contractSpecifications[index].is_editable = !this.contractSpecifications[index].is_editable; }
            });
        },

        removeContractSpecification({ item }) {
            const index = this.contractSpecifications.findIndex(item2 => item === item2);

            this.contractSpecifications.splice(index, 1);
        },

        handleManualLiquidationDropdownClick(item) {
            this.$emit('manual-liquidatation', item);
        },
    },
};
</script>
