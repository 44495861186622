<template>
  <div class="mb-1">
    <validation-observer
      #default="{ invalid }"
      ref="tableForm"
    >
      <b-row>
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            name="Data Inicial"
            rules="required|after-today"
          >
            <b-form-group
              label="Data Inicial"
              label-for="initial-date"
            >
              <b-form-input
                id="initial-date"
                v-model="initialDate"
                type="date"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger text-left">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            name="Data Final"
            :rules="`required|end-date:${initialDate}`"
          >
            <b-form-group
              label="Data Final"
              label-for="final-date"
            >
              <b-form-input
                id="final-date"
                v-model="finalDate"
                type="date"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger text-left">{{ errors[0] }}</small>
            </b-form-group>

          </validation-provider>
        </b-col>

        <b-col md="12">
          <b-form-group
            label="Valor Efetivo"
            label-for="value"
          >
            <b-form-input
              id="value"
              v-model="value"
              v-money="money"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            name="Documento"
            rules="required|tax-id"
          >

            <b-form-group
              label="CNPJ Adquirente"
              label-for="taxIdAcquirer"
            >
              <b-form-input
                id="axIdAcquirer"
                v-model="acquirer"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                placeholder="CNPJ Adquirente"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger text-left">{{ errors[0] }}</small>
            </b-form-group>

          </validation-provider>
        </b-col>

        <b-col md="12">
          <b-form-group
            label="Esquema"
            label-for="scheme"
          >

            <multiselect
              id="scheme"
              v-model="paymentScheme"
              :options="paymentSchemeOptions"
              placeholder="Esquema"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              select-label=""
              selected-label=""
              deselect-label=""
              label="name"
              track-by="name"
            >

              <span slot="noResult">
                Nenhum Produto Encontrado!
              </span>
            </multiselect>
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            label="Sobrepor URs"
            label-for="add"
          >
            <b-form-checkbox
              id="add"
              v-model="group"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-button
        :disabled="invalid"
        variant="primary"
        @click="generateURs"
      >
        Gerar URs
      </b-button>
    </validation-observer>
  </div>
</template>

<script>
import {
    BCol, BRow, BButton, BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue';

import Multiselect from 'vue-multiselect';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import HolidayService from '@/services/webservices/HolidaysService';

export default {
    components: {
        BCol,
        BRow,
        BButton,
        BFormGroup,
        BFormInput,
        Multiselect,
        ValidationProvider,
        BFormCheckbox,
        ValidationObserver,
    },

    props: {
        paymentSchemes: {
            default: () => [],
            type: Array,
        },

        contractSpecifications: {
            default: () => [],
            type: Array,
        },
    },

    data() {
        return {
            initialDate: null,
            finalDate: null,
            acquirer: null,
            paymentScheme: [],
            group: true,
            value: 0,
            data: [],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: false,
            },
        };
    },

    computed: {
        paymentSchemeOptions() {
            return this.paymentSchemes.map(item => ({
                name: item.text,
            }));
        },
    },

    mounted() {
        this.data = this.contractSpecifications;
    },

    methods: {
        async getHolidaysByYear(year) {
            const { data } = await HolidayService.getHolidaysByYear(year);
            return data;
        },

        async getHolidaysByBetweenYear(initialYear, finalYear) {
            const holidaysPromises = [];

            for (let i = initialYear; i <= finalYear; i += 1) {
                holidaysPromises.push(this.getHolidaysByYear(i));
            }

            const promisesResolved = await Promise.all(holidaysPromises);
            return promisesResolved;
        },

        removeTimeZone(date) {
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        },

        isWeekend(date) {
            const dayOfWeek = date.getDay();
            return (dayOfWeek === 6) || (dayOfWeek === 0);
        },

        formatDateToInput(date) {
            const day = (`0${date.getDate()}`).slice(-2);
            const month = (`0${date.getMonth() + 1}`).slice(-2);

            const today = `${date.getFullYear()}-${month}-${day}`;

            return today;
        },

        async generateURs() {
            const finalDate = this.removeTimeZone(new Date(this.finalDate));
            const initialDate = this.removeTimeZone(new Date(this.initialDate));

            const holidayRaw = await this.getHolidaysByBetweenYear(initialDate.getFullYear(), finalDate.getFullYear());
            const holidaysWitouthSubarrays = holidayRaw.reduce((list, sub) => list.concat(sub), []);

            const holidays = holidaysWitouthSubarrays.map(item => this.removeTimeZone(new Date(item.date)));

            const { paymentScheme: paymentSchemeSelected } = this;

            const data = [];

            for (let d = initialDate; d <= finalDate; d.setDate(d.getDate() + 1)) {
                if (!this.isWeekend(d) && !holidays.find(item => +item === +d)) {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const { name: paymentScheme } of paymentSchemeSelected) {
                        data.push({
                            expected_settlement_date: this.formatDateToInput(new Date(d)),
                            receivable_debtor: this.acquirer,
                            payment_scheme: paymentScheme,
                            effect_value: this.value,
                            is_possible_edit: true,
                            is_editable: false,
                            original_asset_holder: this.$store.getters['merchant/taxId'],
                        });
                    }
                }
            }

            this.$emit('generate', {
                data,
                group: !this.group,
            });
        },
    },
};
</script>

    <style lang="scss">

    .multiselect__option--highlight {
        background: #2E2C6C !important;
    }

    .multiselect__tag {
        background: #2E2C6C !important;
    }

    .dark-layout {
        .multiselect__tags {
            background-color: #283046;
            border-color: #3b4253;
        }
    }
    </style>

    <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
