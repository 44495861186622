<template>
  <div>
    <h6>
      ID: <span>{{ manualLiquidation.id }}</span>
    </h6>
    <h6>
      Previsão da Liquidação:
      <span>{{ manualLiquidation.expected_settlement_date | date }}</span>
    </h6>
    <h6>
      Adquirente: <span>{{ manualLiquidation.receivable_debtor | tax_id }}</span>
    </h6>
    <h6>
      Beneficiário:
      <span>{{ manualLiquidation.original_asset_holder | tax_id }}</span>
    </h6>
    <h6>
      Valor Efetivo: <span>{{ manualLiquidation.effect_value | value }}</span>
    </h6>
    <h6>
      Liquidado: <span> {{ liquidated | value }}
      </span>
    </h6>
    <h6>
      Saldo em Aberto: <span> {{ openBalance | value }} </span>
    </h6>

    <b-form-group label="Liquidado Manual">
      <validation-provider
        #default="{ errors }"
        name="Liquidacao Manual"
        :rules="`required|liquidated_manual:${JSON.stringify(
          manualLiquidation
        )}`"
      >
        <b-form-input
          v-model="manualLiquidationValue"
          v-money="money"
          :state="errors.length > 0 ? false : null"
        />
        <small class="text-danger text-left">{{ errors[0] }}</small>

        <b-button
          class="mt-1 d-block w-100"
          variant="primary"
          :disabled="errors.length > 0"
          @click="manuallyTriggerLiquidation"
        >
          Lançar Liquidação Manual
        </b-button>
      </validation-provider>
    </b-form-group>

  </div>
</template>

<script>
import { BButton, BFormGroup, BFormInput } from 'bootstrap-vue';

import { ValidationProvider } from 'vee-validate';

const removeCurrencySymbols = value => (typeof value === 'string'
    ? +value.replace(/[R$]/g, '').replace(',', '.')
    : value);

export default {
    components: {
        BButton, BFormGroup, BFormInput, ValidationProvider,
    },

    props: {
        manualLiquidation: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            manualLiquidationValue: 0,
        };
    },

    computed: {
        openBalance() {
            return (
                this.manualLiquidation.effect_value
            - this.manualLiquidation.paid_value
            - removeCurrencySymbols(this.manualLiquidation.manual_liquidated || 0));
        },

        liquidated() {
            return this.manualLiquidation.paid_value
            + removeCurrencySymbols(this.manualLiquidation.manual_liquidated || 0);
        },
    },

    methods: {
        removeCurrencySymbols(item) {
            return removeCurrencySymbols(item);
        },

        manuallyTriggerLiquidation() {
            const { manualLiquidationValue: value } = this;
            this.$emit('click-button', removeCurrencySymbols(value));
        },
    },
};
</script>
