/* eslint-disable no-restricted-syntax */
import OptInGetterByRegister from '@/register/get-optin';

const removeSymbolsTaxId = taxId => taxId.replace(/[^a-zA-Z0-9]/g, '');

function group(data) {
    const dataSorted = data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    const results = [];

    const cnpjs = new Set(data.map(item => item.cnpj));

    for (const cnpj of cnpjs) {
        const filteredData = dataSorted.filter(item => item.cnpj === cnpj);
        const groupedByDateAndValue = {};

        for (const item of filteredData) {
            const key = `${item.value}-${item.date}`;
            if (!groupedByDateAndValue[key]) {
                groupedByDateAndValue[key] = { types: new Set(), dates: [] };
            }
            groupedByDateAndValue[key].types.add(item.type);
            groupedByDateAndValue[key].dates.push(item.date);
        }

        const aggregated = {};

        for (const key in groupedByDateAndValue) {
            const { types, dates } = groupedByDateAndValue[key];
            const typeKey = `${Array.from(types).sort().join(',')} ${key.split('-')[0]}`;

            if (!aggregated[typeKey]) {
                aggregated[typeKey] = { types, dates: [] };
            }
            aggregated[typeKey].dates.push(...dates);
        }

        for (const typeKey in aggregated) {
            const { types, dates } = aggregated[typeKey];
            if (dates.length > 0) {
                results.push({
                    type: Array.from(types),
                    cnpj,
                    startDate: dates[0],
                    endDate: dates[dates.length - 1] || dates[0],
                    value: +typeKey.split(' ')[1],
                });
            }
        }
    }

    return results;
}
export default class CercMapper {
    static mapSpecifications(specifications, divisionMethod, percentage, isEdit) {
        const removeSymbolsMoney = value => (typeof value === 'string'
            ? +value.replace(/[R$ .]/g, '').replace(',', '.')
            : value);

        const specificationsFiltered = specifications.filter(({ expected_settlement_date }) => {
            if (isEdit) {
                return new Date(expected_settlement_date) > new Date();
            }

            return true;
        });

        const aggruped = group(specificationsFiltered.map(item => ({
            date: item.expected_settlement_date,
            type: item.payment_scheme,
            value: removeSymbolsMoney(item.effect_value),
            cnpj: removeSymbolsTaxId(item.receivable_debtor),
        })));

        return aggruped.map(item => ({
            start_date: item.startDate,
            end_date: item.endDate,
            division_method: divisionMethod,
            effect_value: divisionMethod === 'percentage' ? percentage : removeSymbolsMoney(item.value),
            payment_schemes: item.type.includes('Todas') ? [] : item.type,
            acquirers: [removeSymbolsTaxId(item.cnpj)],
        }));
    }

    static map(data, isEdit = false) {
        const removeSymbolsMoney = value => (typeof value === 'string'
            ? +value.replace(/[R$]/g, '').replace(',', '.')
            : value);

        const cerc = {
            register: data.register,
            contract_due_date: data.contract_due_date,
            signature_date: data.signature_date,
            percentage: data.percentage,
            contract_value: data.contract_value,
            beneficiary: data.beneficiary,
            effect_type: removeSymbolsMoney(data.effect_type),
            bank_account: data.bank_account,
            specifications: CercMapper.mapSpecifications(data.contract_specifications, data.division_method, data.percentage, isEdit),
        };

        if (!isEdit) {
            const optIn = new OptInGetterByRegister().get('cerc');
            if (optIn) {
                cerc.opt_in_id = optIn;
            }
        }

        return cerc;
    }

    static mapViewSpecifications(endorsementSpecifications) {
        return endorsementSpecifications.map(item => ({
            id: item.id,
            expected_settlement_date: item.start_date,
            effect_value: item.effect_value ?? 0,
            receivable_debtor: item.endorsement_acquirers[0].cnpj,
            payment_scheme: item.endorsement_payment_schemes[0].payment_scheme.code,
            paid_value: item.paid_value ?? 0,
            division_method: item.division_method,
            open_balance: item.open_balance ?? 0,
            manual_posting: item.manual_posting ?? 0,
        }));
    }

    static toView(data) {
        return {
            ...data,
            register: 'CERC',
            contract_value: `${data.contract_value.toFixed(2)}`,
            effect_type: data.effect_type_id,
            percentage: data.division_method === 'percentage' ? data.endorsement_effects[0].effect_value : '',
            bank_account: {
                ...data.account,
                bank: data.account.isbp.name,
            },
            endorsement_specifications: undefined,
        };
    }
}
