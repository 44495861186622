<template>
  <b-button variant="primary">
    <vue-json-to-csv
      :json-data="baseData"
      :labels="labels"
      :separator="';'"
      csv-title="contrato"
      type="csv"
    >
      Download Modelo Excel
    </vue-json-to-csv>
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue';

import VueJsonToCsv from 'vue-json-to-csv';

export default {
    components: {
        BButton,
        VueJsonToCsv,
    },

    data() {
        return {
            baseData: [
                {
                    expected_settlement_date: '30/08/2022',
                    receivable_debtor: '22.036.090/0001-03',
                    payment_scheme: 'MCC',
                    effect_value: 49.3,
                },
            ],

            labels: {
                expected_settlement_date: {
                    title: 'previsao_da_liquidacao',
                },
                receivable_debtor: { title: 'cnpj_adquirente' },
                payment_scheme: { title: 'esquema_de_pagamento' },
                effect_value: { title: 'valor_efetivo' },
            },
        };
    },
};
</script>
